import '../styles/globals.css';

import Head from 'next/head';
import { AppProps } from 'next/app';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import theme from '../src/theme';
import createEmotionCache from '../src/utils/createEmotionCache';
import AppWrapper from '../src/components/layouts/AppWrapper';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const primaryColor = theme.palette.primary.main;
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta content='IE=edge' httpEquiv='X-UA-Compatible' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes'
        />
        <meta name='robots' content='noindex, nofollow' />
        <meta name='screen-orientation' content='portrait' />
        <meta name='application-name' content={`myBrand`} />
        <meta name='description' content={`myBrand`} />
        <meta name='theme-color' content={primaryColor} />
        <meta name='format-detection' content='telephone=no' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta
          name='msapplication-config'
          content='/assets/favicon_package/browserconfig.xml'
        />
        <meta name='msapplication-tooltip' content={`myBrand`} />
        <meta name='msapplication-navbutton-color' content={primaryColor} />
        <meta name='msapplication-TileColor' content={primaryColor} />
        <meta
          name='msapplication-TileImage'
          content={`/assets/favicon_package/mstile-150x150.png`}
        />
        <meta name='msapplication-tap-highlight' content='no' />
        <meta name='apple-mobile-web-app-title' content={`myBrand`} />
        <meta name='apple-mobile-web-app-capable' content={`yes`} />
        <meta
          name='apple-mobile-web-app-status-bar-style'
          content={`default`}
        />

        <link rel='icon' href='/assets/favicon_package/favicon.ico' />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/assets/favicon_package/favicon-16x16.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/assets/favicon_package/favicon-32x32.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='152x152'
          href={`/assets/favicon_package/apple-touch-icon.png`}
        />
        <link
          rel='apple-touch-icon'
          sizes='167x167'
          href={`/assets/favicon_package/apple-touch-icon.png`}
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href={`/assets/favicon_package/apple-touch-icon.png`}
        />
        <link
          rel='mask-icon'
          href='/assets/favicon_package/safari-pinned-tab.svg'
          color={primaryColor}
        />
        <link rel='manifest' href={'/assets/manifest.json'} />

        <title>{'myBrand'}</title>
      </Head>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppWrapper wrapper={true}>
          <Component {...pageProps} />
        </AppWrapper>
      </ThemeProvider>
    </CacheProvider>
  );
}
