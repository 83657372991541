import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import COLORS from '../constants/colors';

// Create a theme instance.
const theme = createTheme({
  components: {
    // Name of the component
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true,
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        htmlColor: COLORS.brandDark,
      },
    },
  },
  typography: {
    button: {
      textTransform: 'capitalize',
    },
  },
  palette: {
    primary: {
      main: COLORS.brandLight,
      contrastText: '#ffffff',
    },
    secondary: {
      main: COLORS.brandDark,
      contrastText: '#ffffff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: COLORS.brandBase,
    },
    text: {
      primary: COLORS.brandDark,
    },
  },
  // shadows: Array(25).fill('none') as Shadows,
});

export default theme;
