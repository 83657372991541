import { ReactNode, useState } from 'react';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

import Link from '../../base/Link';
import Logo from '../../common/Logo';

import { Navigation } from '../../../constants/data/navigation';
import { useRouter } from 'next/router';

interface NavItemPropTypes {
  name: string;
  icon?: ReactNode;
  href?: string;
  items?: Array<NavItemPropTypes>;
}

const NavigationPanel = () => {
  const router = useRouter();
  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const MenuItem = (props: any) => {
    const { icon, name, href, onClick } = props;
    return (
      <ListItemButton
        component={Link}
        href={href}
        onClick={onClick}
        selected={href === router.pathname}
      >
        {icon && <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon>}
        <ListItemText primary={name} sx={{ pl: icon ? 0 : 5 }} />
      </ListItemButton>
    );
  };

  const SubMenu = (props: any) => {
    const { icon, name, items } = props;
    return (
      <>
        <ListItemButton onClick={handleClick}>
          {icon && (
            <ListItemIcon sx={{ minWidth: '40px' }}>{icon}</ListItemIcon>
          )}
          <ListItemText primary={name} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {items.map((navItem: NavItemPropTypes) => (
              <MenuItem {...navItem} key={navItem.href} />
            ))}
          </List>
        </Collapse>
      </>
    );
  };

  return (
    <div>
      <Toolbar
        sx={{
          justifyContent: 'center',
        }}
      >
        <Logo />
      </Toolbar>
      <Divider />
      <List disablePadding>
        {Navigation?.map(navItem => {
          return navItem.items ? (
            <SubMenu {...navItem} key={navItem.href} />
          ) : (
            <MenuItem {...navItem} key={navItem.href} />
          );
        })}
      </List>
    </div>
  );
};

export default NavigationPanel;
