import Image from 'next/image';

const Logo = () => (
  <div
    style={{
      position: 'relative',
      height: 50,
      width: 180,
    }}
  >
    <Image
      src={'/assets/img/logo/logo.png'}
      alt='myBrand'
      layout='fill'
      objectFit='contain'
      priority
    />
  </div>
);

export default Logo;
