import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';

export const Navigation = [
  {
    name: 'Dashboard',
    href: '/dashboard',
    icon: <DashboardOutlinedIcon />,
  },
  {
    name: 'Rewards',
    href: '/rewards',
    icon: <RedeemOutlinedIcon />,
    items: [
      {
        name: 'Partners',
        href: '/rewards/partner',
      },
      {
        name: 'Rewards',
        href: '/rewards/reward',
      },
    ],
  },
];
