import React from 'react';
import { useRouter } from 'next/router';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';

import Link from '../Link';

const AppBreadcrumb = () => {
  const router = useRouter();
  const pathSnippets = router.asPath.split('/').filter(i => i);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    return index === pathSnippets.length - 1 ? (
      <Typography color='text.secondary' key={url} variant='body2'>
        {pathSnippets[index]}
      </Typography>
    ) : (
      <Link href={url} key={url} underline='none' variant='body2'>
        {pathSnippets[index]}
      </Link>
    );
  });

  const breadcrumbItems = [
    <Link href='/dashboard' key='home' underline='none' variant='body2'>
      Home
    </Link>,
  ].concat(extraBreadcrumbItems);

  return (
    <Breadcrumbs
      aria-label='breadcrumb'
      sx={{ textTransform: 'capitalize', my: 2 }}
    >
      {breadcrumbItems}
    </Breadcrumbs>
  );
};

export default React.memo(AppBreadcrumb);
