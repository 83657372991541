import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';

import NavigationPanel from '../../global/NavigationPanel';
import Logo from '../../common/Logo';
import Breadcrumb from '../../base/Breadcrumb';

const drawerWidth = 240;

interface PageWrapperPropTypes {
  children: React.ReactNode;
  wrapper?: boolean;
}

export default function AppWrapper({
  children,
  wrapper = true,
}: PageWrapperPropTypes) {
  const isSm = useMediaQuery((theme: any) => theme.breakpoints.up('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const currentYear = new Date().getFullYear();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const PageContent = () => (
    <Box component='main' sx={{ flexGrow: 1, backgroundColor: 'white', p: 2 }}>
      {children}
    </Box>
  );

  if (!wrapper) {
    return <PageContent />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Box
        component='nav'
        sx={{
          width: { sm: mobileOpen ? 0 : drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label='nav items'
      >
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          <NavigationPanel />
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: mobileOpen ? 0 : drawerWidth,
            },
          }}
          open
        >
          <NavigationPanel />
        </Drawer>
      </Box>

      <AppBar
        position='fixed'
        color='inherit'
        sx={{
          width: {
            sm: `calc(100% - ${mobileOpen ? 0 : drawerWidth}px)`,
          },
          ml: { sm: `${mobileOpen ? 0 : drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          {(mobileOpen || !isSm) && <Logo />}
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          minHeight: '100vh',
          px: 2,
          width: { sm: mobileOpen ? '100%' : `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Breadcrumb />
        <PageContent />
        <Box
          component='footer'
          sx={{ textAlign: { xs: 'center', sm: 'right' }, my: 2 }}
        >
          <Typography variant='body2'>
            © {currentYear} myBrand, All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
